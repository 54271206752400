export default {
  headerMenuBackground: "rgba(0, 0, 0, 0.44)",
  primaryLight: "#FFF",
  primaryGrey: "#7B7B7B",
  primaryGrey_light: "#e0e0e0",
  primaryDark: "rgb(41, 41, 41)",
  btnRedStyle: "#ad050a",
  quote: "#959494",
  homepageSections: {
    fundamentals: "#FFD700",
    fundamentals_light: "#fffbe5",
    athletic: "#FF5860",
    athletic_light: "#ffe0e1",
    health: "#7EF6D2",
    health_light: "#dffbf3",
    about_me: "#FFD66D",
  },
};

import styled from "styled-components";
import FlexBox from "../../FlexBox/FlexBox";

export const StyledNav = styled(FlexBox)`
  width: fit-content;
  margin: 0 auto;
  height: 100vh;
  position: relative;

  & > a:first-child {
    @media screen and (max-width: 750px) {
      margin-top: 15px;
    }

    & img {
      width: 100%;
      height: 100%;
    }
  }
`;

import styled from "styled-components";
import fonts from "../../assets/style/fonts";
import colors from "../../assets/style/colors";
import FlexBox from "../../components/FlexBox/FlexBox";

export const ContactUsContainer = styled.div`
  img {
    margin-top: 50px;
    max-width: 1040px;
    width: 90%;
    filter: brightness(0.9);
  }
`;
export const SectionTitle = styled.h2`
  font-size: 3em;
  font-weight: 100;
  letter-spacing: 9px;
  line-height: 90px;
  text-transform: uppercase;
  font-family: ${fonts.secondaryLight};
  @media screen and (max-width: 760px) {
    margin-top: 20px;
  }
`;
export const FreeSessionText = styled.p`
  margin: 50px 0;
  max-width: 640px;
  font-family: ${fonts.defaultLight};
  font-size: 1.7em;
  line-height: 2em;
  position: relative;
  @media screen and (max-width: 760px) {
    width: 90%;
    margin: 10px 0 40px 0;
  }

  &::before {
    content: "";
    position: absolute;
    top: 43px;
    left: -60px;
    height: 3px;
    width: 61px;
    transform: rotate(-90deg);
    background-color: ${colors.homepageSections.athletic};

    @media screen and (max-width: 770px) {
      top: -20px;
      left: 50%;
      transform: translateX(-50%) !important;
    }
  }
`;
export const GetInTouchContainer = styled(FlexBox)`
  margin: 50px 0;
  position: relative;
  @media screen and (max-width: 770px) {
    margin: 50px 0 0 0;
  }
  &::before {
    content: "";
    position: absolute;
    width: 65px;
    height: 3px;
    background-color: ${colors.homepageSections.athletic};
  }
  h3 {
    margin-top: 30px;
    text-transform: uppercase;
    position: relative;
    span {
      font-family: ${fonts.titlePrimary};
      font-size: 3.6rem;
      letter-spacing: 15px;
      font-weight: 400;
      position: absolute;
      top: 0px;
      @media screen and (min-width: 400px) and (max-width: 760px) {
        top: 5px;
        font-size: 2rem;
        letter-spacing: 13px;
      }
      @media screen and (max-width: 400px) {
        top: 0px;
        font-size: 1.6rem;
        letter-spacing: 10px;
      }
    }
    strong {
      font-family: ${fonts.titlePrimary};
      font-size: 14rem;
      letter-spacing: 50px;
      font-weight: 500;
      color: #f2f2f2;
      @media screen and (min-width: 760px) and (max-width: 1000px) {
        font-size: 16rem;
        letter-spacing: 20px;
      }
      @media screen and (min-width: 400px) and (max-width: 760px) {
        font-size: 6rem;
        letter-spacing: 20px;
      }
      @media screen and (max-width: 400px) {
        font-size: 5rem;
        letter-spacing: 15px;
      }
    }
  }
`;

import React from "react";
import { ContactUsContainer, SectionTitle, FreeSessionText, GetInTouchContainer } from "./Contact.style";
import { ContactData } from "./ContactData";
import FlexBox from "../../components/FlexBox/FlexBox";
import Button from "../../components/Button/Button";
import SocialMedia from "../../components/SocialMedia/SocialMedia";
import ContactImg from "../../assets/images/Contact/Contact.png";

const Contact = () => {
  return (
    <ContactUsContainer>
      <FlexBox align="center" direction="column">
        <SectionTitle>free session</SectionTitle>
        <FreeSessionText>{ContactData.freeSessionContent.map((content) => content)}</FreeSessionText>
        <Button link="https://booking.appointy.com/Prymeset" text="Try a free session" target="_blank" />
        <img src={ContactImg} alt="image coach amir smiling" />
      </FlexBox>
      <GetInTouchContainer justify="center">
        <h3>
          <FlexBox direction="column" align="center">
            <span>get in touch</span>
            <strong>contact</strong>
          </FlexBox>
        </h3>
      </GetInTouchContainer>
      <SocialMedia big />
    </ContactUsContainer>
  );
};

export default Contact;

import React, { useState } from "react";
import useScreenType from "react-screentype-hook";
import { ContentBoxContainer, ImgWithHalo, TextContent, QuoteContainer } from "./ContentBox.style";
import FlexBox from "../../components/FlexBox/FlexBox";

const ImgContent = ({ isVisible, ElementRef, sectionImgWord, img, sectionName, rightHalo }) => (
  <ImgWithHalo rightHalo={rightHalo} sectionName={sectionName} ref={ElementRef} isVisible={isVisible}>
    <h1>{sectionImgWord}</h1>
    <div className="imgHolder">
      <img src={img} alt={`image-${sectionName}`} />
      <div className={`glow-wrap`}>
        <i className="glow"></i>
      </div>
    </div>
  </ImgWithHalo>
);

const ContentBox = ({
  sectionName,
  sectionImgWord,
  sectionTitleP1,
  sectionTitleP2,
  sectionTitle2,
  sectionTitle2P2,
  smallMarge,
  extraSmallMarge,
  quote,
  quoteAuthor,
  sectionText,
  img,
  rightDisplay,
  noBefore,
  withHalo,
  rightHalo,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const listItemRef = React.useRef();
  const screenType = useScreenType();
  const ElementRef = React.useCallback((node) => {
    if (listItemRef.current) listItemRef.current.disconnect();
    listItemRef.current = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.intersectionRatio > 0.25) {
          setIsVisible(true);
        }
      },
      { threshold: [0.25] }
    );
    if (node) listItemRef.current.observe(node);
  }, []);
  return (
    <ContentBoxContainer sectionName={sectionName}>
      <FlexBox align="center" justify="center" className="contentBoxFlex">
        <TextContent
          noBefore={noBefore}
          smallMarge={smallMarge}
          extraSmallMarge={extraSmallMarge}
          sectionName={sectionName}
          rightDisplay={rightDisplay}
          isVisible={isVisible}
        >
          {(sectionTitleP1 || sectionTitleP2) && (
            <h2>
              {sectionTitleP1} <span>{sectionTitleP2}</span>
            </h2>
          )}
          {sectionTitle2 && (
            <h3>
              {sectionTitle2} <span>{sectionTitle2P2}</span>
            </h3>
          )}
          {quote && (
            <QuoteContainer>
              <span id="quote">{quote}</span>
              {quoteAuthor && <span id="quoteAuthor">{quoteAuthor}</span>}
            </QuoteContainer>
          )}
          {screenType.isMobile && sectionName.toLowerCase() !== "aboutus" && (
            <ImgContent
              withHalo={withHalo}
              rightHalo={rightHalo}
              isVisible={isVisible}
              ElementRef={ElementRef}
              sectionImgWord={sectionImgWord}
              img={img}
              sectionName={sectionName}
            />
          )}
          {sectionText.map((text, index) => (
            <p className={`sectionPara`} key={index}>
              {text}
            </p>
          ))}
        </TextContent>
        {(!screenType.isMobile || sectionName.toLowerCase() === "aboutus") && (
          <ImgContent
            withHalo={withHalo}
            rightHalo={rightHalo}
            isVisible={isVisible}
            ElementRef={ElementRef}
            sectionImgWord={sectionImgWord}
            img={img}
            sectionName={sectionName}
          />
        )}
      </FlexBox>
    </ContentBoxContainer>
  );
};

export default ContentBox;

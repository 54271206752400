import React, { useState, useEffect } from "react";
import HeaderLogo from "../../assets/images/header/logo-wth-titlte.png";
import FlexBox from "../FlexBox/FlexBox";
import styled from "styled-components";
import colors from "../../assets/style/colors";
import fonts from "../../assets/style/fonts";
import Hamburger from "hamburger-react";
import { Link } from "react-router-dom";

const MenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ isPanelVisible }) => (isPanelVisible ? "100%" : "0")};
  max-width: 100vw;
  z-index: 100;
  transition: all 0.5s cubic-bezier(0.77, 0, 0.18, 1);

  & .small-logo {
    width: 130px;
    object-fit: cover;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    z-index: 200;
    @media screen and (max-width: 750px) {
      width: 120px;
      top: 25px;
      left: 15px;
      right: 10px;
    }
  }
`;
const StyledBtn = styled.ul`
  width: fit-content;
  margin-top: 0;
  margin-left: 15px;
  cursor: pointer;
  position: relative;
  margin-left: auto;
  top: 5px;
  right: 20px;
  z-index: 200;
  .hamburger-react div {
    width: 40px !important;
    height: 2px !important;
  }
  @media screen and (max-width: 750px) {
    top: 0;
    right: 0px;
    margin-bottom: 0;
    .hamburger-react div {
      width: 25px !important;
    }
  }
`;
const StyledNav = styled.div`
  & nav {
    display: flex;
    flex-direction: column;
  }
  position: fixed;
  top: ${({ isPanelVisible }) => (!isPanelVisible ? "-100%" : "0")};
  width: 100%;
  height: 100%;
  background-color: ${({ isPanelVisible }) => (isPanelVisible ? "rgba(0, 0, 0, 0.98)" : colors.btnRedStyle)};
  z-index: 100;
  transition: top 0.3s ease, background-color 0.3s ease;

  & a {
    padding: 10px 0px;
    color: ${colors.primaryGrey};
    font-size: 5rem;
    font-family: ${fonts.defaultLight};
    @media screen and (max-width: 750px) {
      font-size: 3rem;
    }
  }
  & .active {
    color: ${colors.primaryLight};
  }
`;
const HamburgerContainer = styled.div`
  width: 100%;
  height: fit-content;
  background-color: rgba(0, 0, 0, 0.4);
  position: relative;
`;

const BurgerMenu = ({ HeaderNavLinks }) => {
  const [isPanelVisible, setIsPanelVisible] = useState(false);
  const menuRef = React.useRef();

  const closeNav = () => {
    setIsPanelVisible(false);
  };

  useEffect(() => {
    var prevScrollpos = window.pageYOffset;
    window.addEventListener("scroll", () => {
      var currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos || isPanelVisible || currentScrollPos === 0) {
        menuRef.current.style.top = "0";
      } else {
        menuRef.current.style.top = "-70px";
      }
      prevScrollpos = currentScrollPos;
    });
  }, [isPanelVisible]);

  return (
    <MenuContainer isPanelVisible={isPanelVisible} ref={menuRef}>
      <FlexBox justify="space-between">
        <HamburgerContainer>
          <StyledBtn>
            <Hamburger Squeeze color="rgb(255 88 96)" toggled={isPanelVisible} toggle={setIsPanelVisible} />
          </StyledBtn>
          <Link to="/home" onClick={closeNav}>
            <img src={HeaderLogo} alt="prymeset logo" className="small-logo" />
          </Link>
        </HamburgerContainer>
        <StyledNav isPanelVisible={isPanelVisible} onClick={(event) => event.stopPropagation()}>
          {<HeaderNavLinks onClick={closeNav} />}
        </StyledNav>
      </FlexBox>
    </MenuContainer>
  );
};

export default BurgerMenu;

import React from "react";
import { Player } from "video-react";
import { AboutUsContainer, AboutUsVideoText } from "./AboutUs.style";
import { AboutUsData } from "./AboutUsData";
import ContentBox from "../../components/ContentBox/ContentBox";
import FlexBox from "../../components/FlexBox/FlexBox";
import Poster from "../../assets/images/AboutUs/video_poster.jpeg";

const AboutUs = () => {
  return (
    <AboutUsContainer>
      <ContentBox
        sectionName={AboutUsData.contentBox.sectionName}
        sectionText={AboutUsData.contentBox.para}
        img={AboutUsData.contentBox.img}
        rightDisplay
      />
      <FlexBox align="center" direction="column">
        <h2>
          <FlexBox align="center" direction="column">
            <span>this isn't about us.</span>
            <span>
              it's about <span id="AboutYou">you</span>.
            </span>
          </FlexBox>
        </h2>
        <AboutUsVideoText>{AboutUsData.videoSection.videoText}</AboutUsVideoText>
        <Player playsInline preload="auto" poster={Poster} src={AboutUsData.videoSection.video} className="video" />
      </FlexBox>
    </AboutUsContainer>
  );
};

export default AboutUs;

import styled from "styled-components";
import fonts from "../../assets/style/fonts";
import colors from "../../assets/style/colors";

export const ServicesContainer = styled.div`
  margin-top: -60px;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  z-index: 10;
  h2 {
    font-size: 3em;
    font-family: ${fonts.secondaryLight};
    font-weight: 200;
    letter-spacing: 10px;
    text-align: center;
    @media screen and (max-width: 760px) {
      margin-top: 50px;
    }
  }
  a {
    display: block;
    margin-bottom: 50px !important;
  }
`;

export const ImgContainer = styled.img`
  max-width: 100%;
  max-height: 60rem;
  display: block;
  margin: 0 auto;
  object-fit: cover;
  filter: brightness(0.8);
  @media screen and (min-width: 1000px) {
    min-width: 900px;
  }
`;
export const ServicesDetailsText = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  font-family: ${fonts.defaultLight};
  font-size: 1.7em;
  line-height: 2em;
  position: relative;
  margin: 100px auto 50px auto;

  @media screen and (max-width: 770px) {
    font-size: 1.5em;
    margin: 50px auto;
  }

  h2 {
    margin: 50px auto;
  }

  p {
    max-width: 820px;
    position: relative;
    margin: 0 auto;
  }

  span {
    display: inline-block;
  }

  & p::before {
    content: "";
    position: absolute;
    top: 38px;
    left: -60px;
    height: 3px;
    width: 61px;
    transform: rotate(-90deg);
    background-color: ${({ beforeColor }) =>
      beforeColor === "fundamentals" ? colors.homepageSections.fundamentals : colors.homepageSections.athletic};
    @media screen and (max-width: 770px) {
      top: -30px;
      left: 50%;
      transform: translateX(-50%) !important;
    }
  }
`;

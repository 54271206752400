import React from "react";
import { NavLink } from "react-router-dom";
import { StyledNav } from "./HeaderMenu.style.js";
import BurgerMenu from "../../BurgerMenu/BurgerMenu";

const HeaderMenu = ({ setIsPanelVisible, isPanelVisible }) => {
  const HeaderNavLinks = ({ onClick }) => (
    <StyledNav direction="column" justify="center">
      <NavLink exact to="/home" onClick={onClick}>
        Home
      </NavLink>
      <NavLink to="/services" onClick={onClick}>
        Services
      </NavLink>
      <NavLink to="/about-us" onClick={onClick}>
        About us
      </NavLink>
      <NavLink to="/contact" onClick={onClick}>
        Contact
      </NavLink>
    </StyledNav>
  );
  return <BurgerMenu HeaderNavLinks={HeaderNavLinks} setIsPanelVisible={setIsPanelVisible} isPanelVisible={isPanelVisible} />;
};

export default HeaderMenu;

import styled from "styled-components";
import colors from "../../assets/style/colors";
import fonts from "../../assets/style/fonts";
import FlexBox from "../FlexBox/FlexBox";

export const GalleryHolder = styled.div`
  position: relative;
`;

export const GalleryContainer = styled(FlexBox)`
  width: fit-content;
  @media screen and (max-width: 760px) {
    width: unset;
    overflow: scroll;
    flex-wrap: unset;
    justify-content: unset;
    gap: 1px;
  }

  & .carousel-indicators {
    bottom: 20px !important;
    .active {
      background-color: red;
    }
    button {
      width: 6px;
      height: 6px;
      border-radius: 50%;
    }
  }

  & .carousel-item {
    transition: transform 0.4s ease;
  }

  & .carousel-control-prev-icon,
  & .carousel-control-next-icon {
    display: none;
  }
`;
export const GalleryItem = styled.div`
  background-image: url(${({ galleryImg }) => galleryImg && galleryImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 1px;
  width: 36em;
  height: 36em;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: grayscale(95%);
  @media screen and (max-width: 760px) {
    min-width: 100%;
    min-height: 30em;
  }

  & span {
    color: ${colors.primaryLight};
    font-family: ${fonts.primary};
    font-weight: 600;
    letter-spacing: 5px;
    position: relative;
    z-index: 10;
    opacity: 0;
    text-transform: uppercase;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.81);
    font-size: 1.1em;
    @media screen and (max-width: 1024px) {
      opacity: 1;
    }
  }
  @media screen and (min-width: 760px) {
    &:hover {
      filter: grayscale(0%);
    }
    &:hover span {
      opacity: 1;
    }

    &:hover::before {
      background-color: rgba(0, 57, 109, 0.7);
    }
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    transition: 0.5s ease background-color;
  }
`;

import styled from "styled-components";
import fonts from "../../assets/style/fonts";
import colors from "../../assets/style/colors";

export const HomepageContainer = styled.div`
  padding: 10px;
  overflow: hidden;

  & > :nth-child(2) {
    @media screen and (max-width: 760px) {
      margin: 0 !important;
    }
  }
`;
export const AboutMeContainer = styled.div`
  h2 {
    font-family: ${fonts.secondaryLight};
    letter-spacing: 15px;
    font-size: 3em;
    font-weight: 100;
    margin-left: 10px;
  }
`;
export const PrymesetAbout = styled.div`
  @media screen and (max-width: 760px) {
    margin: 50px 0;
  }
  main {
    position: relative;
    width: 100vw;
    min-height: 100vh;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      color: inherit;
      text-decoration: none;
      &:hover {
        opacity: 0.6;
      }
    }

    .credit {
      position: absolute;
      top: 2%;
      left: 2%;
      color: rgba(white, 0.4);
      p {
        margin: 0;
        font-size: 0.8em;
        text-transform: uppercase;
        letter-spacing: 0.08em;
      }
    }

    .img {
      width: 80%;
      height: auto;
      position: absolute;
      // cursor: none;
      transition: 650ms ease;
      &-01 {
        z-index: 2;
      }
      &-02 {
        z-index: 1;
        opacity: 0;
        pointer-events: none;
        filter: blur(100px);
      }
    }

    .img-01:hover {
      opacity: 0;
    }

    .img-01:hover ~ .img-02 {
      opacity: 1;
      filter: blur(0);
    }
  }
  h3 {
    font-family: ${fonts.titlePrimary};
    letter-spacing: 10px;
    font-weight: 100;
    font-size: 2.5em;

    span {
      font-family: ${fonts.titlePrimary};
      letter-spacing: 0px;
      color: ${colors.homepageSections.athletic};
      padding: 0 10px 0 2px;
    }
  }
  h2 {
    font-family: ${fonts.secondaryLight};
    text-align: center;
    letter-spacing: 15px;
    font-size: 5em;
    font-weight: 100;

    @media screen and (max-width: 760px) {
      font-size: 3em;
      margin: 20px 0;
    }

    span {
      color: ${colors.homepageSections.athletic};
    }
  }

  p {
    margin-top: 10px;
    span {
      font-size: 1.6em;
      margin: 0 20px;
      font-family: ${fonts.secondaryLight};
      letter-spacing: 5px;
      @media screen and (max-width: 760px) {
        margin: 0px;
      }
      &:first-child {
        margin-right: 10px;
      }
    }
  }
`;

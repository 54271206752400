import styled from "styled-components";
import fonts from "../../assets/style/fonts";
import colors from "../../assets/style/colors";

export const NotFoundContainer = styled.div`
  background-color: ${colors.primaryDark};
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & h1,
  h2 {
    color: ${colors.primaryLight};
  }

  h1 {
    font-family: ${fonts.titlePrimary};
    font-size: 7rem;
    letter-spacing: 10px;
    margin-top: 10px;
  }

  h2 {
    font-family: ${fonts.secondaryPrimary};
    font-size: 2rem;
    letter-spacing: 2px;
    margin-top: 10px;
    text-align: center;
  }

  img {
    width: 140px;
  }
`;

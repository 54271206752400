import styled from "styled-components";
import fonts from "../../assets/style/fonts";

export const ServicesContainer = styled.div`
  h2 {
    @media screen and (max-width: 760px) {
      margin-top: 60px;
    }
    font-size: 3em;
    font-family: ${fonts.secondaryLight};
    font-weight: 200;
    letter-spacing: 10px;
  }

  p {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px auto;
    font-size: 1.7em;
    margin-bottom: 40px;
    & span {
      color: grey;
      margin-top: 10px;
      max-width: 600px;
    }
  }
  h2,
  p {
    text-align: center;
  }
`;

import React from "react";
import "react-animated-slider/build/horizontal.css";
import useScreenType from "react-screentype-hook";
import { useHistory } from "react-router-dom";
import { GalleryContainer, GalleryItem } from "./Gallery.style";
import Carousel from "react-bootstrap/Carousel";
import "./style.css";

const GalleryItems = ({ GalleryContent, history }) =>
  GalleryContent.map((gallery) => (
    <GalleryItem key={gallery.name} galleryImg={gallery.img} onClick={() => history.push(`/services${gallery.link}`)}>
      <span>{gallery.name}</span>
    </GalleryItem>
  ));

const Gallery = ({ GalleryContent }) => {
  const history = useHistory();
  const screenType = useScreenType();

  return (
    <GalleryContainer wrap="wrap" justify="center" maxWidth="730px" margin="0 auto">
      {screenType.isMobile ? (
        <Carousel>
          {GalleryContent.map((gallery) => (
            <Carousel.Item>
              <GalleryItem key={gallery.name} galleryImg={gallery.img} onClick={() => history.push(`/services${gallery.link}`)}>
                <span>{gallery.name}</span>
              </GalleryItem>
            </Carousel.Item>
          ))}
        </Carousel>
      ) : (
        <GalleryItems GalleryContent={GalleryContent} history={history} />
      )}
    </GalleryContainer>
  );
};

export default Gallery;

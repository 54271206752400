import styled from "styled-components";
import colors from "../../assets/style/colors";
import LogoPrymeset from "../../assets/images/header/simple_logo.png";

export const OverLay = styled.div`
  position: fixed;
  width: 100%;
  height: ${({ pageLoaded }) => (pageLoaded ? "0%" : "100%")};
  top: 0;
  z-index: 1000;
  background: black;
  transition: height 0.2s ease;
  background-image: url(${LogoPrymeset});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80px;
  transition: height 0.2s ease;

  &::before {
    display: ${({ pageLoaded }) => (pageLoaded ? "none" : "")};
    content: "";
    position: absolute;
    width: 250px;
    height: 250px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border-left: 2px solid ${colors.btnRedStyle};
    opacity: 0.5;
    animation: rotation 5s ease-in-out infinite forwards;
    @keyframes rotation {
      from {
        transform: translate(-50%, -50%) rotate(0deg);
      }

      to {
        transform: translate(-50%, -50%) rotate(720deg);
      }
    }
  }
`;

import styled from "styled-components";

export const StyledBtn = styled.div`
  @media screen and (max-width: 750px) {
    text-align: ${({ goBackBtn }) => goBackBtn && "center !important"};
  }
  .animated-button1 {
    clip-path: ${({ goBackBtn }) => (goBackBtn ? "polygon(0% 0%,87% 0%,100% 100%,12% 100%)" : "polygon(12% 0%, 100% 0%, 87% 100%, 0% 100%)")};
    transition: transform 0.5s ease;
    text-decoration: ${({ goBackBtn }) => goBackBtn && "underline"};
  }
  .animated-button:hover::before {
    opacity: 0.2;
  }
  .animated-button1:hover {
    transform: ${({ goBackBtn }) => goBackBtn && "translateX(-10px) !important"};
  }

  .animated-button span {
    position: absolute;
  }

  .animated-button span:nth-child(1) {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 2px;
    background: -webkit-gradient(linear, right top, left top, from(rgba(8, 20, 43, 0)), to(#2662d9));
    background: linear-gradient(to left, rgba(8, 20, 43, 0), #2662d9);
    -webkit-animation: 2s animateTop linear infinite;
    animation: 2s animateTop linear infinite;
  }

  @-webkit-keyframes animateTop {
    0% {
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
    100% {
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  }

  @keyframes animateTop {
    0% {
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
    100% {
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  }

  .animated-button span:nth-child(2) {
    top: 0px;
    right: 0px;
    height: 100%;
    width: 2px;
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(8, 20, 43, 0)), to(#2662d9));
    background: linear-gradient(to top, rgba(8, 20, 43, 0), #2662d9);
    -webkit-animation: 2s animateRight linear -1s infinite;
    animation: 2s animateRight linear -1s infinite;
  }

  @-webkit-keyframes animateRight {
    0% {
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
    }
    100% {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
  }

  @keyframes animateRight {
    0% {
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
    }
    100% {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
  }

  .animated-button span:nth-child(3) {
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 2px;
    background: -webkit-gradient(linear, left top, right top, from(rgba(8, 20, 43, 0)), to(#2662d9));
    background: linear-gradient(to right, rgba(8, 20, 43, 0), #2662d9);
    -webkit-animation: 2s animateBottom linear infinite;
    animation: 2s animateBottom linear infinite;
  }

  @-webkit-keyframes animateBottom {
    0% {
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
    100% {
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
  }

  @keyframes animateBottom {
    0% {
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
    100% {
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
  }

  .animated-button span:nth-child(4) {
    top: 0px;
    left: 0px;
    height: 100%;
    width: 2px;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(8, 20, 43, 0)), to(#2662d9));
    background: linear-gradient(to bottom, rgba(8, 20, 43, 0), #2662d9);
    -webkit-animation: 2s animateLeft linear -1s infinite;
    animation: 2s animateLeft linear -1s infinite;
  }

  @-webkit-keyframes animateLeft {
    0% {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
    100% {
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
    }
  }

  @keyframes animateLeft {
    0% {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
    100% {
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
    }
  }

  .animated-button1 {
    background: ${({ goBackBtn }) => (goBackBtn ? "black" : "linear-gradient(-30deg, #7a1114 50%, #501012 50%)")};
    padding: ${({ goBackBtn }) => (goBackBtn ? "15px 50px" : "20px 50px")};
    margin: 12px;
    display: inline-block;
    -webkit-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    overflow: hidden;
    font-size: 20px;
    color: white;
    cursor: pointer;
    letter-spacing: 2.5px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);

    @media screen and (max-width: 750px) {
      font-size: 1.4rem;
      padding: 16px 50px;
      margin-top: ${({ goBackBtn }) => goBackBtn && "50px !important"};
      margin-bottom: ${({ goBackBtn }) => goBackBtn && "0 !important"};
    }
    @media screen and (min-width: 750px) and (max-width: 1360px) {
      font-size: 1.5rem;
      padding: 15px 50px;
    }
  }

  .animated-button1::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #ad8585;
    opacity: 0;
    -webkit-transition: 0.2s opacity ease-in-out;
    transition: 0.2s opacity ease-in-out;
  }

  .animated-button1:hover::before {
    opacity: 0.2;
  }

  .animated-button1 span {
    position: absolute;
  }

  .animated-button1 span:nth-child(1) {
    top: -1px;
    left: 0px;
    width: 100%;
    height: 5px;
    background: -webkit-gradient(linear, right top, left top, from(rgba(43, 8, 8, 0)), to(#d92626));
    background: linear-gradient(to left, rgba(43, 8, 8, 0), #d92626);
    -webkit-animation: 3s animateTop linear infinite;
    animation: 3s animateTop linear infinite;
  }

  @keyframes animateTop {
    0% {
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
    100% {
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  }

  .animated-button1 span:nth-child(3) {
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 5px;
    background: -webkit-gradient(linear, left top, right top, from(rgba(43, 8, 8, 0)), to(#d92626));
    background: linear-gradient(to right, rgba(43, 8, 8, 0), #d92626);
    -webkit-animation: 3s animateBottom linear infinite;
    animation: 3s animateBottom linear infinite;
  }

  @keyframes animateBottom {
    0% {
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
    100% {
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
  }
`;

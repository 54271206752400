import React from "react";
import { StyledBtn } from "./Button.style";

const Button = ({ text = "back", onClick, goBackBtn, link, target }) => (
  <StyledBtn onClick={onClick} goBackBtn={goBackBtn} className="CustomButton">
    <a href={link} className="animated-button1" target={target}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      {text}
    </a>
  </StyledBtn>
);

export default Button;

import GirlTraining from "../../assets/images/AboutUs/girl_training.jpeg";
import Video from "../../assets/video/prymeset_video.mp4";
export const AboutUsData = {
  contentBox: {
    sectionName: "AboutUs",
    para: [
      "Health chasers, athletes and individuals like YOU who are looking to better themselves are the REASON WE DO THIS.",
      "if you’re here, you already know that guidance through your ATHLETIC JOURNEY is a must ! ",
      "The feeling of knowing whether you’re doing everything to reach your prime or if there’s something lacking in your training because of the limited knowledge or experience you have is a common feeling… And that’s where we come in to help you ! ",
    ],
    img: GirlTraining,
  },
  videoSection: {
    videoText:
      "Everybody has to figure out what drives them… WHAT DRIVES YOU ?! We'll help you with a specific plan and coaching based on what you need, but more importantly, what you want to get out of yourself because at the end of the day, it's YOU vs YOU",
    video: Video,
  },
};

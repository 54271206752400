import React from "react";
import { Link } from "react-router-dom";
import { NotFoundContainer } from "./NotFound404.style";
import Button from "../../components/Button/Button";
import SimpleLogo from "../../assets/images/simple_logo.png";

const NoMatchPage = () => {
  return (
    <NotFoundContainer>
      <img src={SimpleLogo} alt="Prymeset white logo" />
      <h1>404</h1>
      <h2>Oops! That page can’t be found.</h2>
      <h2>It seems like you got lost... Don't worry we got your back</h2>
      <Button link="/" text="Back to home" />
    </NotFoundContainer>
  );
};

export default NoMatchPage;

export default {
  default: "OpenSans-Regular",
  defaultLight: "OpenSans-Light",
  primary: "Roboto-Regular",
  secondary: "Oswald-Regular",
  secondaryLight: "Oswald-Light",
  titlePrimary: "Anton-Regular",
  secondaryPrimary: "Almarai-Regular",
  secondaryPrimaryLight: "Almarai-Light",
  secondaryPrimaryBold: "Almarai-ExtraBold",
  quote: "Lateef-Regular",
};

import { HashRouter } from "react-router-dom";
import { AppRouter } from "./router/AppRouter/AppRouter";
import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  return (
    <HashRouter>
      <AppRouter />
    </HashRouter>
  );
};

export default App;

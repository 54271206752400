import React from "react";
import styled from "styled-components";

const FlexBoxContainer = styled.div`
  display: flex;
  ${({ direction }) => direction && `flex-direction: ${direction};`}
  ${({ direction }) => !direction && `flex-direction: row;`}
  ${({ wrap }) => wrap && `flex-wrap: ${wrap};`}
  ${({ justify }) => justify && `justify-content: ${justify};`}
  ${({ fullWidth }) => fullWidth && "width: 100%;"}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  ${({ align }) => align && `align-items: ${align};`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ margin }) => !margin && `margin: 0;`}
  ${({ position }) => position && `position: ${position};`}
`;

const FlexBox = ({ children, ...props }) => <FlexBoxContainer {...props}>{children}</FlexBoxContainer>;

export default FlexBox;

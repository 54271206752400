import styled, { css } from "styled-components";
import colors from "../../assets/style/colors";
import fonts from "../../assets/style/fonts";

export const HeaderContainer = styled.div`
  overflow: hidden;
  position: relative;
  background-color: ${colors.primaryLight};
  @media screen and (max-width: 760px) {
    min-height: 100vh;
  }
  & > img {
    width: 100%;
    object-fit: cover;
    position: relative;
    display: block;
    top: -90px;
    transition: opacity 1s ease;
    animation: ${({ locationChanges }) =>
      locationChanges ? "scaleUP 1s cubic-bezier(0.77, 0, 0.18, 1) forwards, appears 1s cubic-bezier(0.77, 0, 0.18, 1) forwards" : ""};
    @media screen and (max-width: 500px) {
      object-position: ${({ location }) => (location !== "/home" ? "" : "-35px 0px")};
    }
    @media screen and (max-width: 760px) {
      top: 0px;
      min-height: 100vh;
    }
  }
  .hide {
    display: none;
  }
`;
export const HeaderTextContent = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
  ${({ location }) =>
    location !== "/home"
      ? css`
          & > div {
            height: 100%;
            transform: translateY(-15%);
            @media screen and (max-width: 760px) {
              transform: unset;
            }
          }
        `
      : css`
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: -13em;
          @media screen and (max-width: 760px) {
            margin-top: 0px;
            align-items: flex-end;
            margin-bottom: 40px;
          }
          @media screen and (min-width: 760px) and (max-width: 1360px) {
            margin-top: -11em;
          }
        `};
  & > div {
    @media screen and (max-width: 760px) {
      margin-bottom: 90px;
    }
  }
  /* title styles */
  h1,
  h2 {
    position: relative;
    overflow: hidden;
    display: block;
    line-height: 1.2;
    color: ${colors.primaryLight};
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.81);
  }
  h1 {
    font-size: 10rem;
    margin-bottom: 0px;
    letter-spacing: 10px;
    font-weight: 500;
    font-family: ${fonts.titlePrimary};
    @media screen and (max-width: 760px) {
      font-size: 3rem;
      margin-bottom: 5px;
      text-align: center;
      max-width: 320px;
    }
    @media screen and (min-width: 760px) and (max-width: 1100px) {
      font-size: 7rem;
      text-align: center;
    }
    @media screen and (min-width: 1100px) and (max-width: 1360px) {
      font-size: 9rem;
    }
  }
  h2 {
    font-size: 6rem;
    letter-spacing: 2px;
    font-family: ${fonts.secondaryPrimaryLight};
    font-weight: 100;
    @media screen and (max-width: 760px) {
      font-size: 2rem;
      margin-bottom: 30px;
    }
    @media screen and (min-width: 760px) and (max-width: 1100px) {
      font-size: 3.5rem;
    }
    @media screen and (min-width: 1100px) and (max-width: 1360px) {
      font-size: 5rem;
    }

    & span {
      font-family: ${fonts.secondaryPrimary};
      font-weight: bold;
      color: ${colors.primaryLight};
    }
  }

  h1::after,
  h2::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: white;
    /* animation: a-ltr-after 1.5s cubic-bezier(0.77, 0, 0.18, 1) forwards; */
    transform: translateX(-101%);
  }

  h1::before,
  h2::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #1c1c1c;
    /* animation: a-ltr-before 1.5s cubic-bezier(0.77, 0, 0.18, 1) forwards; */
    transform: translateX(0);
    display: none;
  }

  h1::before,
  h1::after {
    animation-delay: 1s;
  }

  h2::before,
  h2::after {
    animation-delay: 1.5s;
  }

  .CustomButton {
    cursor: pointer;
    position: relative;
    bottom: -16em;
    /* animation: comeFromRight 1.5s cubic-bezier(0.77, 0, 0.18, 1) forwards; */
    @media screen and (max-width: 760px) {
      bottom: ${({ location }) => (location === "/home" ? "0" : "-16em")};
    }
    @media screen and (min-width: 760px) and (max-width: 1100px) {
      bottom: -8em;
    }
    @media screen and (min-width: 1100px) and (max-width: 1360px) {
      bottom: -10em;
    }
  }
`;

import FoundationImg from "../../assets/images/Homepage/sections/fundations_img.png";
import AthleticImg from "../../assets/images/Homepage/sections/athlectic_img.png";
import HealthImg from "../../assets/images/Homepage/sections/health_img.png";
import AboutMe from "../../assets/images/Homepage/sections/About_me.png";
export const HomepageData = {
  fundations: {
    sectionName: "Fundamentals",
    sectionImgWord: "Foundations",
    SectionTitleP1: "Master the",
    quote: "“You can't build a great building on a weak foundation”",
    quoteAuthor: "Gordon B. Hinckley",
    sectionText: [
      "People tend to jump on advanced workouts and trendy exercises. But not all the exercises that mimic these patterns are right for EVERYBODY.",
      "There are 6 movements that form the basis of  all the ways we can move our body in our life and daily activities : Squat - Hinge - Push - Lunge - Pull - Carry",
      "At Prymeset, WE WILL go through a selection process of exercises and custom-fit workout programs to ensure your safety but also empower you in reaching your PRIME in a INJURY-FREE and seamless way.",
    ],
    img: FoundationImg,
  },
  athlectic: {
    sectionName: "Athletic",
    sectionImgWord: "Athleticism",
    SectionTitleP1: "Be",
    quote: "“if you have a body, you are an athlete !”",
    quoteAuthor: "",
    sectionText: [
      "Many individuals think that we only need to play sports in order to get athletic.. THIS ISN’T TRUE !",
      "We believe that ANYBODY can reach their ability to perform a variety of movements with confidence and coordination.",
      "After mastering the FUNDAMENTALS, we’ll help you be more explosive and develop Coordination, Body Awareness, Agility to adapt efficiently to any environnement in your life! ",
      "Your body was created to move and whether you are young or old, your LIFESTYLE  depends on how WELL your body works !",
    ],
    img: AthleticImg,
  },
  health: {
    sectionName: "healthy",
    sectionImgWord: "Health",
    SectionTitleP1: "Stay",
    SectionTitleP1: "Stay",
    sectionTitle2: "And",
    sectionTitle2P2: "Injury FREE",
    quote: "",
    quoteAuthor: "",
    sectionText: [
      "Feeling STRESSED ?! Having BACK PAIN ? What about the tightness YOU FEEL on your shoulders and your hips ? ",
      "Health and injury prevention are keys to a successful life ! You can be a sports addict, love weightlifting, or run every once in a while; the bottom line is, working out can also cause pain",
      "We will help you bulletproof your body and work on increasing your range of motion because a healthy functioning body is PAIN FREE !",
    ],
    img: HealthImg,
  },
  aboutMe: {
    sectionName: "aboutMe",
    sectionImgWord: "Amir",
    sectionText: [
      "Amir is a strength and conditioning coach and is the founder of Prymeset, a brand dedicated to helping one’s performance, life, health and personal growth.",
      "While holding a bachelor degree in sports coaching, strength and conditioning,",
      "He developed a methodology based on the basics and an understanding of one’s body mechanics to help you reach your potential and be more athletic ! ",
      "Furthermore, it was in Boxing where he did found his initial passion. With 9 years of experience in boxing including achievements such as tournaments with the national team of Belgium, gold medals in Holland, Belgium and Denmark.",
      "He understands the psychological and physiological aspect of elite athletes who aspire to be the best they can be.",
    ],
    img: AboutMe,
  },
};

import styled, { css } from "styled-components";
import colors from "../../assets/style/colors";
import fonts from "../../assets/style/fonts";

export const ContentBoxContainer = styled.div`
  margin: 50px 0;
  padding: 0 35px;
  position: relative;
  @media screen and (max-width: 770px) {
    padding: 0 10px;
    margin: 30px 0;
  }
  @media screen and (min-width: 770px) and (max-width: 1100px) {
    padding: 0 5px;
  }

  .contentBoxFlex {
    @media screen and (max-width: 770px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  .photo {
    position: relative;
    display: block;
    width: fit-content;

    & > div {
      overflow: hidden;
    }
    @media screen and (max-width: 770px) {
      margin-bottom: 40px;
      max-width: 500px;
    }
    &::before {
      position: absolute;
      content: "";
      width: 101%;
      height: 105%;
      top: -10px;
      background-color: ${({ sectionName }) =>
        sectionName.toLowerCase() === "fundamentals"
          ? `${colors.homepageSections.fundamentals_light}`
          : sectionName.toLowerCase() === "athletic"
          ? `${colors.homepageSections.athletic_light}`
          : sectionName.toLowerCase() === "healthy"
          ? `${colors.homepageSections.health_light}`
          : `${colors.primaryGrey_light}`};
      z-index: 1;
      transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
  .photo img {
    width: 470px;
    position: relative;
    display: block;
    transform: scale(1.2);
    object-fit: cover;
    opacity: 0.5;
    margin-top: -10px;
    transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
    @media screen and (max-width: 770px) {
      width: 100%;
    }
    @media screen and (min-width: 770px) and (max-width: 1000px) {
      width: 350px;
    }
    @media screen and (min-width: 1000px) and (max-width: 1360px) {
      width: 400px;
    }
    @media screen and (min-width: 1360px) {
      width: 500px;
    }
  }

  .photo .glow-wrap {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    margin-top: -10px;
  }

  .photo .glow {
    display: block;
    position: absolute;
    width: 40%;
    height: 200%;
    background: rgba(255, 255, 255, 0.2);
    top: 0;
    filter: blur(5px);
    transform: rotate(45deg) translate(-450%, 0);
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition-delay: 2s;
  }

  .imgWithText {
    h1 {
      transform: translate(0%, 20%) rotate(-90deg);
      @media screen and (max-width: 770px) {
        transform: translate(0%, 20%) rotate(0deg);
      }
      opacity: 1;
    }
    .glow {
      transform: rotate(45deg) translate(450%, 0);
      transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    &::before {
      background: rgba(255, 255, 255, 1);
      height: 0%;
    }

    & img {
      transform: scale(1);
      opacity: 1;
    }
  }

  h1 {
    position: absolute;
    z-index: 1;
    transform: translate(0%, -200%) rotate(-90deg);
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    margin: 0;
    font-size: 7rem;
    line-height: 1.2;
    letter-spacing: 14px;
    font-family: ${fonts.titlePrimary};
    text-align: center;
    letter-spacing: 0.19em;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
    transition: all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);

    @media screen and (max-width: 770px) {
      transform: translate(30%, 20%) rotate(0deg);
      font-size: 3.5rem;
    }
    @media screen and (min-width: 760px) and (max-width: 1100px) {
      font-size: 5rem;
    }
    @media screen and (min-width: 1100px) and (max-width: 1360px) {
      font-size: 6rem;
    }
    ${({ sectionName }) =>
      sectionName.toLowerCase() === "fundamentals"
        ? css`
            color: ${colors.homepageSections.fundamentals};
            opacity: 1;
            bottom: 255px;
            left: -240px;
            @media screen and (max-width: 770px) {
              bottom: -14px;
              right: 10px;
            }
            @media screen and (min-width: 770px) and (max-width: 1100px) {
              bottom: 100px;
              left: -170px;
            }
            @media screen and (min-width: 1100px) and (max-width: 1360px) {
              bottom: 100px;
              left: -210px;
            }
            @media screen and (min-width: 1360px) {
              bottom: 100px;
              left: -240px;
            }
          `
        : sectionName.toLowerCase() === "athletic"
        ? css`
            color: ${colors.homepageSections.athletic};
            opacity: 1;
            bottom: 250px;
            right: -240px;
            @media screen and (max-width: 770px) {
              bottom: -13px;
              right: 90px;
            }
            @media screen and (min-width: 770px) and (max-width: 1100px) {
              bottom: 90px;
              right: -170px;
            }
            @media screen and (min-width: 1100px) and (max-width: 1360px) {
              bottom: 100px;
              right: -205px;
            }
            @media screen and (min-width: 1360px) {
              bottom: 100px;
              right: -240px;
            }
          `
        : sectionName.toLowerCase() === "healthy"
        ? css`
            color: ${colors.homepageSections.health};
            opacity: 1;
            bottom: 160px;
            left: -130px;
            @media screen and (max-width: 770px) {
              bottom: -14px;
              right: -30px;
            }
            @media screen and (min-width: 770px) and (max-width: 1100px) {
              bottom: 0px;
              left: -95px;
            }
            @media screen and (min-width: 1100px) and (max-width: 1360px) {
              bottom: 0px;
              left: -110px;
            }
            @media screen and (min-width: 1360px) {
              bottom: 0px;
              left: -130px;
            }
          `
        : sectionName.toLowerCase() === "aboutme"
        ? css`
            color: ${colors.homepageSections.about_me};
            opacity: 0;
            bottom: 100px;
            right: -90px;
            @media screen and (max-width: 770px) {
              bottom: -13px;
              right: 50px;
            }
            @media screen and (min-width: 770px) and (max-width: 1100px) {
              bottom: -20px;
              right: -65px;
            }
            @media screen and (min-width: 1100px) and (max-width: 1360px) {
              bottom: -50px;
              right: -80px;
            }
            @media screen and (min-width: 1360px) {
              bottom: -50px;
              right: -95px;
            }
          `
        : ""};
  }
  img {
    width: 550px;
    filter: brightness(0.9);
    @media screen and (max-width: 1100px) {
      width: 100%;
      max-width: ${({ sectionName }) => (sectionName.toLowerCase() === "services" ? "390px" : "550px")};
    }
    @media screen and (min-width: 1100px) {
      width: 450px;
    }
    @media screen and (min-width: 1100px) and (max-width: 1360px) {
      width: 500px;
    }
  }
`;
export const TextContent = styled.div`
  margin-right: 50px;
  order: ${({ rightDisplay }) => rightDisplay && "1"};
  position: relative;
  @media screen and (max-width: 770px) {
    order: unset;
    margin-right: 0;
  }
  p {
    font-family: ${fonts.defaultLight};
  }
  p,
  h2,
  h3 {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      height: 100%;
      width: ${({ isVisible }) => (isVisible ? `0%` : "100%")};
      transition: all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
      background-color: ${({ sectionName }) =>
        sectionName.toLowerCase() === "fundamentals" || sectionName.toLowerCase() === "services"
          ? `${colors.homepageSections.fundamentals_light}`
          : sectionName.toLowerCase() === "athletic" || sectionName.toLowerCase() === "aboutus"
          ? `${colors.homepageSections.athletic_light}`
          : sectionName.toLowerCase() === "healthy"
          ? `${colors.homepageSections.health_light}`
          : `${colors.homepageSections.fundamentals_light}`};
    }
  }
  ${({ rightDisplay, extraSmallMarge, smallMarge }) =>
    rightDisplay
      ? css`
          order: 1;
          margin-left: ${extraSmallMarge ? "50px" : smallMarge ? "70px" : "130px"};
          margin-right: ${smallMarge && "0"};
          @media screen and (max-width: 770px) {
            order: unset;
            margin-left: 0 !important;
          }
          @media screen and (min-width: 770px) and (max-width: 1100px) {
            margin-left: 80px;
            margin-right: 0;
            max-width: 350px;
          }
          @media screen and (min-width: 1100px) and (max-width: 1360px) {
            margin-left: 100px;
          }
        `
      : css`
          order: initial;
          margin-right: 150px;
          @media screen and (max-width: 770px) {
            margin-right: 0 !important;
          }
          @media screen and (min-width: 770px) and (max-width: 1100px) {
            margin-right: 70px;
            max-width: 450px;
          }
          @media screen and (min-width: 1100px) and (max-width: 1360px) {
            margin-right: 80px;
          }
        `};
  h2,
  h3 {
    font-size: 2.8em;
    position: relative;
    span {
      text-transform: uppercase;
      margin-left: ${({ sectionName }) =>
        sectionName.toLowerCase() === "fundamentals"
          ? "30px"
          : sectionName.toLowerCase() === "athletic"
          ? "15px"
          : sectionName.toLowerCase() === "healthy"
          ? "10px"
          : ""};
      color: ${({ sectionName }) =>
        sectionName.toLowerCase() === "fundamentals"
          ? `${colors.homepageSections.fundamentals}`
          : sectionName.toLowerCase() === "athletic"
          ? `${colors.homepageSections.athletic}`
          : sectionName.toLowerCase() === "healthy"
          ? `${colors.homepageSections.health}`
          : ""};
    }
  }

  h3 {
    margin-left: 50px;
    margin-bottom: 50px;
    @media screen and (min-width: 770px) and (max-width: 1360px) {
      margin-bottom: 20px;
    }
  }

  h2 {
    &::before {
      display: ${({ noBefore }) => noBefore && "none"};
      content: "";
      position: absolute;
      height: 3px;
      opacity: ${({ isVisible }) => (isVisible ? `1` : "0")};
      transform: rotate(-90deg);
      transition: opacity 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
      ${({ sectionName }) =>
        sectionName.toLowerCase() === "aboutus"
          ? css`
              width: 100px;
              left: -80px;
              top: 60px;
            `
          : css`
              width: 33px;
              left: -27px;
              top: 20px;
            `};
      background-color: ${({ sectionName }) =>
        sectionName.toLowerCase() === "fundamentals"
          ? `${colors.homepageSections.fundamentals}`
          : sectionName.toLowerCase() === "athletic" || sectionName.toLowerCase() === "aboutus"
          ? `${colors.homepageSections.athletic}`
          : sectionName.toLowerCase() === "healthy"
          ? `${colors.homepageSections.health}`
          : ""};
    }
  }

  h2,
  p {
    display: flex;
    flex-direction: ${({ sectionName }) => (sectionName.toLowerCase() === "athletic" || sectionName.toLowerCase() === "healthy" ? "row" : "column")};
  }
  .sectionPara {
    max-width: 700px;
    font-size: 1.9em;
    margin-bottom: 20px;
    line-height: 2em;
    @media screen and (max-width: 760px) {
      max-width: 400px;
      font-size: 1.5em;
      padding: 0 5px;
    }
    @media screen and (min-width: 760px) and (max-width: 1360px) {
      max-width: 500px;
      font-size: 1.6em;
    }
    @media screen and (min-width: 1360px) {
      max-width: 600px;
      font-size: 1.7em;
    }
  }
`;

export const QuoteContainer = styled.p`
  width: fit-content;
  margin-left: 25px;
  margin-bottom: 50px;
  @media screen and (max-width: 1100px) {
    margin-left: 0;
    margin-bottom: 20px;
  }
  #quote {
    font-size: 3em;
    font-family: ${fonts.quote};
    color: ${colors.quote};
    @media screen and (max-width: 770px) {
      font-size: 2.1em;
      text-align: center;
    }
    @media screen and (min-width: 770px) and (max-width: 1360px) {
      font-size: 2.5em;
    }
  }
  #quoteAuthor {
    font-size: 1.4em;
    color: ${colors.primaryDark};
    text-align: right;
  }
`;

export const ImgWithHalo = styled.div`
  position: relative;
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  @media screen and (max-width: 900px) {
    box-shadow: transparent;
    margin: 20px 0 40px 0;
  }
  @media screen and (min-width: 900px) {
    ${({ sectionName }) =>
      sectionName.toLowerCase() === "aboutus"
        ? css`
            box-shadow: ${({ isVisible }) => (isVisible ? `-23px 25px 3px 5px ${colors.homepageSections.athletic}` : "")};
            width: 450px;
          `
        : sectionName.toLowerCase() === "services"
        ? css`
            box-shadow: ${({ isVisible }) => (isVisible ? `-23px 25px 3px 5px ${colors.homepageSections.fundamentals}` : "")};
          `
        : sectionName.toLowerCase() === "healthy"
        ? "10px"
        : ""};
  }
  .imgHolder {
    overflow: hidden;

    & img {
      transition: transform 1s cubic-bezier(0.645, 0.045, 0.355, 1);
      transform: ${({ isVisible }) => (isVisible ? "scale(1)" : "scale(1.3)")};
    }
  }

  & h1 {
    opacity: ${({ isVisible }) => (isVisible ? "1" : "0")};
  }

  &::before {
    content: "";
    position: absolute;
    background-color: ${({ sectionName }) =>
      sectionName.toLowerCase() === "fundamentals" || sectionName.toLowerCase() === "services"
        ? `${colors.homepageSections.fundamentals_light}`
        : sectionName.toLowerCase() === "athletic" || sectionName.toLowerCase() === "aboutus"
        ? `${colors.homepageSections.athletic_light}`
        : sectionName.toLowerCase() === "healthy"
        ? `${colors.homepageSections.health_light}`
        : `${colors.homepageSections.fundamentals_light}`};
    width: 100%;
    height: ${({ isVisible }) => (isVisible ? "0%" : "100%")};
    transition: all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
    z-index: 10;
  }
`;

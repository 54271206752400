import React from "react";
import { HomepageContainer, AboutMeContainer, PrymesetAbout } from "./Homepage.style";
import FlexBox from "../../components/FlexBox/FlexBox";
import ContentBox from "../../components/ContentBox/ContentBox";
import { HomepageData } from "./HomepageData";

const Homepage = () => {
  const { fundations, athlectic, health, aboutMe } = HomepageData;
  return (
    <HomepageContainer>
      <PrymesetAbout>
        <FlexBox justify="center" align="center" direction="column">
          <h3>
            PR<span>[ Y ]</span>MESET
          </h3>
          <h2>
            IT'S ABOUT <span>YOU</span>!
          </h2>
          <p>
            <span>YOUR NEEDS !</span>
            <span>YOUR PRIME !</span>
          </p>
        </FlexBox>
      </PrymesetAbout>
      <ContentBox
        sectionName={fundations.sectionName}
        sectionImgWord={fundations.sectionImgWord}
        sectionTitleP1={fundations.SectionTitleP1}
        sectionTitleP2={fundations.sectionName}
        quote={fundations.quote}
        quoteAuthor={fundations.quoteAuthor}
        sectionText={fundations.sectionText}
        img={fundations.img}
      />
      <ContentBox
        sectionName={athlectic.sectionName}
        sectionImgWord={athlectic.sectionImgWord}
        sectionTitleP1={athlectic.SectionTitleP1}
        sectionTitleP2={athlectic.sectionName}
        quote={athlectic.quote}
        quoteAuthor={athlectic.quoteAuthor}
        sectionText={athlectic.sectionText}
        img={athlectic.img}
        rightDisplay
      />
      <ContentBox
        sectionName={health.sectionName}
        sectionImgWord={health.sectionImgWord}
        sectionTitleP1={health.SectionTitleP1}
        sectionTitleP2={health.sectionName}
        sectionTitle2={health.sectionTitle2}
        sectionTitle2P2={health.sectionTitle2P2}
        quote={health.quote}
        quoteAuthor={health.quoteAuthor}
        sectionText={health.sectionText}
        img={health.img}
      />
      <AboutMeContainer>
        <FlexBox justify="center">
          <h2>BEHIND PRYMESET</h2>
        </FlexBox>
        <ContentBox
          sectionName={aboutMe.sectionName}
          sectionImgWord={aboutMe.sectionImgWord}
          sectionText={aboutMe.sectionText}
          img={aboutMe.img}
          rightDisplay
        />
      </AboutMeContainer>
    </HomepageContainer>
  );
};

export default Homepage;

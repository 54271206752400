import styled from "styled-components";
import fonts from "../../assets/style/fonts";
import colors from "../../assets/style/colors";

export const AboutUsContainer = styled.div`
  h2 {
    letter-spacing: 9px;
    line-height: 90px;
    @media screen and (max-width: 770px) {
      line-height: 40px;
    }
    @media screen and (max-width: 500px) {
      max-width: 290px;
    }
    & span {
      text-transform: uppercase;
      font-family: ${fonts.secondaryLight};
      font-size: 2.5em;
      font-weight: 100;
      @media screen and (max-width: 770px) {
        font-size: 2.5em;
        text-align: center;
        line-height: 55px;
        margin-bottom: 20px;
      }
      @media screen and (min-width: 770px) {
        &:first-child {
          margin-top: 50px;
        }
      }
    }
    & #AboutYou {
      color: ${colors.homepageSections.athletic};
      font-size: 1em;
    }
  }
  .video {
    margin-top: 50px;
    max-width: 1040px;
    width: 90%;
    outline: none;
    padding-top: 40.25% !important;

    @media screen and (max-width: 770px) {
      padding-top: 0 !important;
      height: 30rem;
    }

    .video-react-big-play-button {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1.5em;
      font-size: 4em;
      border: none;
      background-color: ${colors.homepageSections.athletic};
      border-radius: 50%;
      transition: all 0.4s;
    }
  }
`;
export const AboutUsVideoText = styled.p`
  margin-top: 50px;
  max-width: 640px;
  width: 90%;
  font-family: ${fonts.defaultLight};
  font-size: 1.7em;
  line-height: 2em;
  position: relative;

  @media screen and (max-width: 770px) {
    font-size: 1.5em;
    max-width: 540px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 43px;
    left: -60px;
    height: 3px;
    width: 61px;
    transform: rotate(-90deg);
    background-color: ${colors.homepageSections.athletic};
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
`;

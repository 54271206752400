import AmirBackground from "../../assets/images/header/Amir_background.png";
import AmirBackgroundMobile from "../../assets/images/header/mobile/Homepage_header.png";
import ServicesBackground from "../../assets/images/header/services_background.png";
import ServicesBackgroundMobile from "../../assets/images/header/mobile/Services_header.png";
import AboutUsBackground from "../../assets/images/header/about_us_background.png";
import AboutUsBackgroundMobile from "../../assets/images/header/mobile/About_header.png";
import ContactBackground from "../../assets/images/header/contact_background.png";
import ContactBackgroundMobile from "../../assets/images/header/mobile/Contact_header.png";
export const HeaderData = {
  home: {
    title: "WE COACH !",
  },
  services: {
    title: "Our Services",
    personalTraining: {
      title: "Personal coaching",
    },
    athleteTraining: {
      title: "Athlete coaching",
    },
    groupTraining: {
      title: "Small group coaching",
    },
    boxingTraining: {
      title: "Boxing coaching",
    },
  },
  about: {
    title: "About us",
  },
  contact: {
    title: "Contact",
  },
  headerBackgroundContent: [
    {
      background: AmirBackground,
      mobileBackground: AmirBackgroundMobile,
      path: "/home",
    },
    {
      background: ServicesBackground,
      mobileBackground: ServicesBackgroundMobile,
      path: "/services",
    },
    {
      background: AboutUsBackground,
      mobileBackground: AboutUsBackgroundMobile,
      path: "/about-us",
    },
    {
      background: ContactBackground,
      mobileBackground: ContactBackgroundMobile,
      path: "/contact",
    },
  ],
};

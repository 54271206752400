import React from "react";
import styled from "styled-components";
import FlexBox from "../FlexBox/FlexBox";
import FacebookLogo from "../../assets/images/Footer/fb.png";
import InstaLogo from "../../assets/images/Footer/instagram.png";
import EmailLogo from "../../assets/images/Footer/email.png";

const StyledSocialMedia = styled(FlexBox)`
  width: 100%;
  ul {
    display: flex;
    li {
      img {
        width: ${({ big }) => (big ? "110px" : "50px")};
        margin: ${({ big }) => (big ? "0px 40px" : "10px")};
        @media screen and (min-width: 400px) and (max-width: 800px) {
          width: ${({ big }) => (big ? "90px" : "60px")};
          margin: 15px;
        }
        @media screen and (max-width: 400px) {
          width: ${({ big }) => (big ? "80px" : "60px")};
          margin: ${({ big }) => (big ? "10px" : "15px")};
        }
      }
    }
  }
`;

const SocialMedia = ({ big }) => {
  return (
    <StyledSocialMedia justify="center" big={big}>
      <ul>
        <li>
          <a href="https://www.facebook.com/Prymeset/" target="_blank">
            <img src={FacebookLogo} alt="Facebook logo" />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/prymeset/" target="_blank">
            <img src={InstaLogo} alt="Instagram logo" />
          </a>
        </li>
        <li>
          <a href="mailto:prymeset@gmail.com?subject=Mail%20from%20our%20Website" target="_blank">
            <img src={EmailLogo} alt="Email logo" />
          </a>
        </li>
      </ul>
    </StyledSocialMedia>
  );
};

export default SocialMedia;

import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { OverLay } from "./AppRouter.style";
import Header from "../../components/Header/Header";
import Homepage from "../../screens/Homepage/Homepage";
import ServicesDetails from "../../screens/ServicesDetails/ServicesDetails";
import { ServicesDetailsData } from "../../screens/ServicesDetails/ServicesDetailsData";
import Services from "../../screens/ServicesPage/Services";
import AboutUs from "../../screens/AboutUs/AboutUs";
import Contact from "../../screens/Contact/Contact";
import Footer from "../../components/Footer/Footer";
import NotFound404 from "../../screens/NotFound404/NotFound404";

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, [pathname]);

  return null;
};

export const AppRouter = () => {
  const [pageLoaded, setPageLoaded] = useState(false);
  const [locationChanges, setLocationChanges] = useState(false);
  const location = useLocation();

  const checkPath = (location) => {
    if (
      location !== "/home" &&
      location !== "/services" &&
      location !== "/services/personal-training" &&
      location !== "/services/athlete-training" &&
      location !== "/services/group-training" &&
      location !== "/services/boxing-training" &&
      location !== "/about-us" &&
      location !== "/contact"
    ) {
      return true;
    }
  };

  useEffect(() => {
    let appWidget = document.querySelector("#app-widget-btn");
    if (appWidget) {
      appWidget.innerHTML = "Book a free session";
      if (location.pathname === "/home" || location.pathname === "/contact" || checkPath(location.pathname)) {
        appWidget.style.display = "none";
      } else {
        appWidget.style.display = "block";
      }
    }
    setLocationChanges(true);
  }, [location]);

  useEffect(() => {
    window.addEventListener("load", (event) => {
      setPageLoaded(true);
    });
  }, [pageLoaded]);

  return (
    <div>
      {!checkPath(location.pathname) && <OverLay pageLoaded={pageLoaded}></OverLay>}
      {!checkPath(location.pathname) && <Header locationChanges={locationChanges} setLocationChanges={setLocationChanges} pageLoaded={pageLoaded} />}
      <ScrollToTop />
      <Switch>
        <Route exact path="/home" component={Homepage} />
        <Redirect exact from="/" to="/home" />
        <Route exact path="/services" component={Services} />
        <Route path="/services/personal-training" render={() => <ServicesDetails serviceData={ServicesDetailsData.personalCoaching} />} />
        <Route path="/services/athlete-training" render={() => <ServicesDetails serviceData={ServicesDetailsData.athleteCoaching} />} />
        <Route path="/services/group-training" render={() => <ServicesDetails serviceData={ServicesDetailsData.groupCoaching} />} />
        <Route path="/services/boxing-training" render={() => <ServicesDetails serviceData={ServicesDetailsData.boxingCoaching} />} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/contact" render={() => <Contact />} />
        <Route component={NotFound404} />
      </Switch>
      {!checkPath(location.pathname) && <Footer />}
    </div>
  );
};

import styled from "styled-components";
import colors from "../../assets/style/colors";
import fonts from "../../assets/style/fonts";
import FlexBox from "../FlexBox/FlexBox";

export const FooterContainer = styled(FlexBox)`
  width: 100%;
  margin-top: 50px;
  padding: 20px 0;
  position: relative;
  background-color: #1d1c1c;
  color: ${colors.primaryLight};
  text-align: center;
  text-transform: uppercase;
`;

export const MainContentContainer = styled(FlexBox)`
  color: white;
  padding: 0 50px;
  @media screen and (max-width: 500px) {
    flex-direction: column !important;
  }
  @media screen and (min-width: 500px) and (max-width: 1000px) {
    flex-wrap: wrap;
    max-width: 620px;
    margin: 0 auto;
  }
  p,
  span {
    color: ${colors.primaryLight};
  }
`;
export const LocationContainer = styled(FlexBox)`
  min-width: 260px;
  @media screen and (min-width: 500px) and (max-width: 1000px) {
    order: 2;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 500px) {
    order: 3;
    margin-bottom: 30px;
  }
  p {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  span {
    color: ${colors.primaryGrey_light};
  }
  strong {
    font-style: italic;
  }
`;
export const FreeSessionContainer = styled(FlexBox)`
  @media screen and (min-width: 500px) and (max-width: 1000px) {
    order: 3;
  }
  @media screen and (max-width: 500px) {
    order: 2;
    margin-bottom: 50px;
  }
  span {
    font-size: 1.1rem;
    letter-spacing: 1px;
    color: lightgrey;
  }
  a {
    padding: 15px 40px !important;
    font-size: 1.2rem !important;
  }
`;
export const LogoContainer = styled(FlexBox)`
  @media screen and (min-width: 500px) and (max-width: 1000px) {
    width: 100%;
    margin: 20px 0 50px 0;
  }
  @media screen and (max-width: 500px) {
    margin: 40px 0;
  }

  & > img {
    width: 100px;
  }
  svg {
    width: 100px;
    height: 100px;
  }
  span {
    display: block;
    font-size: 1.3rem;
    font-family: ${fonts.secondaryLight};
    letter-spacing: 5px;
    text-transform: uppercase;
    font-weight: bold;
    color: lightgrey;
    margin: 10px 0;
  }
`;
export const TermsContainer = styled(FlexBox)`
  max-width: 186rem;
  margin: 0 auto;
  width: inherit;
  padding: 0 20px;
  & > * {
    color: ${colors.primaryGrey_light};
  }

  a:hover {
    color: ${colors.homepageSections.athletic};
  }
`;

import PersonalCoaching from "../../assets/images/Services/Personal_coaching.jpg";
import privateCoaching from "../../assets/images/Services/private_coaching.jpg";
import AthleteCoaching from "../../assets/images/Services/Athlete_coaching.png";
import AthleteCoachingLarge from "../../assets/images/Services/Athlete_coaching_large.png";
import GroupCoaching from "../../assets/images/Services/Group_coaching.png";
import GroupCoachingLarge from "../../assets/images/Services/Group_coaching_large.png";
import BoxingCoaching from "../../assets/images/Services/Boxing_coaching_details.png";
import BoxingCoachingLarge from "../../assets/images/Services/Boxing_coaching_large.png";

export const ServicesDetailsData = {
  personalCoaching: {
    introText: [
      "Are you frustrated that you’ve tried so hard with so many workouts, but have not reached your PEAK ?!",
      "Whatever the goal is, coming back from an injury, getting leaner or getting stronger, we will set an individual plan and periodized program according to YOUR needs and personal objectives. ",
    ],
    contentBox: {
      sectionName: "services",
      para: [
        "What's your goal ?",
        "- Building muscle",
        "- Fat loss",
        "- Get strong",
        "- HIIT / Functional Training / Circuit Training",
        "- Injury prevention and rehabilitation",
      ],
      img: privateCoaching,
    },
    extraInfos: {
      text: "SO THAT you don’t need to sacrifice your SOCIAL life or time with your family to reach your GOALS and become ATHLETIC ! SHORT & INTENSE based on your level !",
      largeImg: PersonalCoaching,
    },
  },
  athleteCoaching: {
    introText: [
      "It’s valuable to know what you’re good at, but it’s more IMPORTANT to know what you LACK in terms of athleticism and sport specific needs ! Even though you may play in a TEAM SPORT or not, you have INDIVIDUALS NEEDS based on your physiological profile and  team position",
      "That’s why a periodised plan and exercise programming are  important to put in place in order to safely reach your PEAK !",
    ],
    contentBox: {
      sectionName: "services",
      para: [
        "What we do ?",
        "- Testing",
        "- Program Based on your test results and physiological profile",
        "- Strength Program",
        "- Conditioning Program",
        "- Prehab Program",
        "- Reduce the likelihood of injuries",
        "- Follow up ",
      ],
      img: AthleteCoaching,
    },
    extraInfos: {
      text: "We will work together to improve your stamina, strength, coordination and more so you can be the BEST player or fighter you can BE ! OFF Season & IN Season Training",
      largeImg: AthleteCoachingLarge,
    },
  },
  groupCoaching: {
    introText: [
      "Group classes of 15-30 persons are a great way to meet new people, workout and have a good time.",
      "But, you’ll find very quickly that your progress is limited due to a couple reasons",
      "Your classmates don’t have the same motivation to progress as you do ! The attention of the coach isn’t entirely focused on you. The participants don’t have the same level or the same pace as you & it makes it hard to learn and practice new skills.",
    ],
    contentBox: {
      sectionName: "services",
      para: [
        "- You’ll benefit from the group dynamics & motivation",
        "- The time we can give you is greater than traditional group lessons.",
        "- You’ll learn to perform the exercises correctly and master them ",
        "- We’ll create a periodised and progressive plan based on your level so you can push your limits together with your group partners",
      ],
      img: GroupCoaching,
    },
    extraInfos: {
      text: "Prymeset offers small group training ! SGT is done with groups of 2-4 people and is semi-private. Bring your partner, teammates or friends and crush the workout together !",
      largeImg: GroupCoachingLarge,
    },
  },
  boxingCoaching: {
    introText: [
      "You want to take away all the STRESS that’s been accumulated throughout the week ?!",
      "You want to improve your CARDIO or  learn the TECHNIQUE and the basics of The Sweet Science ? ",
    ],
    contentBox: {
      sectionName: "services",
      para: ["- Individual or in small group ", "- Fundamentals of boxing ", "- Cardio Boxing", "- Coordination & Rythm", "- Pad work"],
      img: BoxingCoaching,
    },
    extraInfos: {
      text: "9 years of experience brought us here, but it will take you a few sessions to live this experience !  Don’t hesitate and see where it can bring you in a few months !",
      largeImg: BoxingCoachingLarge,
    },
  },
};

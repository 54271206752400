import PersonalCoaching from "../../assets/images/Services/Personal_coaching.jpg";
import AthleteCoaching from "../../assets/images/Services/Athlete_coaching.png";
import GroupCoaching from "../../assets/images/Services/Group_coaching_large.png";
import BoxingCoaching from "../../assets/images/Services/Boxing_coaching.png";
export const ServicesData = {
  galleryContent: [
    {
      name: "Personal Coaching",
      img: PersonalCoaching,
      link: "/personal-training",
    },
    {
      name: "Athlete Coaching",
      img: AthleteCoaching,
      link: "/athlete-training",
    },
    {
      name: "Small group Coaching",
      img: GroupCoaching,
      link: "/group-training",
    },
    {
      name: "Boxing Coaching",
      img: BoxingCoaching,
      link: "/boxing-training",
    },
  ],
};
